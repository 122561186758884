import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Our Portfolio Performance in 2021`}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <h2>{`Overview`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/live-trading-vs-backtests/"
          }}>{`Live trading vs. backtesting`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/?strategy-tag=meta"
          }}>{`All-Stars family of portfolios`}</a></li>
      </ul>
    </Aside>
    <Border mdxType="Border">
      <p>{`This article is outdated. Check our `}<a parentName="p" {...{
          "href": "/articles/review-2022/"
        }}>{`2022 Review`}</a>{` instead.`}</p>
    </Border>
    <p>{`Overall, 2021 was an excellent year for investors. After closing the year 2020 at 3,756.88, the `}<em parentName="p">{`S&P 500`}</em>{` index climbed up to 4,766.18, a return of almost 27%. Even better, dividends paid by the benchmark's constituents added another 1.8% of return. In this article, we want to dive deeper into what made 2021 a great year and how our strategies performed.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9ef7ecbf307c40db9de537e69064f534/21b4d/spx-volatility-momentum.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABy0lEQVQoz41TXXPaMBD0//9t7UOagHEggAMYbLBlyda3dNuRCi1tXnozNzrtnldna11cu5bqek/njpG1HjEGCiEghEAxRjwHEeHBhZB7IbWDC0TechjFqdBaU/CGVvVIykZ6PPtH4zkyTs+HSO1h1UDwPax1VDjnMrHbt+iZzCJJNt4n+pqZADwHPIOba1h1zrAxNgvmluG4p7E9I+gJep7RNt3fowIIeeMRPUewA0AT1MTQHXaZcdZQYYzF8nWDobuhrs9YvZRotlvslkvw9gJyGqSvUP0G3e4bnDildwBjEozNkCPDy/cFVcstOONUNMcL2ssAZQJuPcPpKrE/cTQ3hXJ9QFX+QFV94K08oHw/YlE1OB6vWK8PKFefqNYnMCHpdXPFjc1UCNZADW9QUwelDGbBoMUn1LCEEgcIwTEKCS4mTPMMqTT6ccbIUy0xSwmlJBktYYymwntPcrqRGPZkVU9OvJPTPRnZUYiPWyWiGMkYk9eEee8oOyQEShop0n0U3tv80R1fwIkK/xPPvkm+TH5NDkgHFlJKstaSnEcyaqR09Yl4ZOKUUl+wNF2q9R1L+5TFL9ffMxLd/5DfWKqTwHPfg/+3L60/AQ3RU4r3mdQiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "S&P 500 Volatility and Momentum from 2007 to 2021",
            "title": "S&P 500 Volatility and Momentum from 2007 to 2021",
            "src": "/static/9ef7ecbf307c40db9de537e69064f534/c1b63/spx-volatility-momentum.png",
            "srcSet": ["/static/9ef7ecbf307c40db9de537e69064f534/5a46d/spx-volatility-momentum.png 300w", "/static/9ef7ecbf307c40db9de537e69064f534/0a47e/spx-volatility-momentum.png 600w", "/static/9ef7ecbf307c40db9de537e69064f534/c1b63/spx-volatility-momentum.png 1200w", "/static/9ef7ecbf307c40db9de537e69064f534/21b4d/spx-volatility-momentum.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Our first chart shows the market's volatility as measured by `}<a parentName="p" {...{
        "href": ""
      }}>{`CBOE's VIX index`}</a>{`. We can see how the pandemic has left its mark. The long-term average for the 30-day VIX is about 18, with bullish years often below 15. In 2021, the average was closer to 20, which is undoubtedly high for a bullish year. However, if we look back to the 2008 recession, we find this not untypical for a rebound after a recession.`}</p>
    <p>{`Looking at the 12-months VIX, we can see how this index jumped up at the pandemic's onset. However, unlike 2008, it seems that the longer-term volatility is receding much slower than it did after the 2008 recession. This lingering volatility intuitively makes sense, given that the pandemic and the supply-chain crisis will continue to influence the markets well into 2022.`}</p>
    <p>{`Momentum has been strongly positive throughout most of the year. However, we notice that momentum has slowly declined in the second half of the year, and even briefly dipped into negative territory in October.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cbacf49a45fd4ec387a05f7dbad35305/21b4d/sector-momentum-abs.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACDElEQVQoz21TXU8TURDdH+6TDzzwgA8GLVAIarRSLIHEBzVGa4gKBkuh9MtStO12+13bbXfvbvfec8zdsk0jTDKZzMzOydw5Z41220KpVMZ1pYRK+QK9/gBCCEgpKaWEUoraADCqSanCHArUfUdMMRwP6LgOjKZl4jKXQzF/jtzFCRpmE7PZDJwbNNQ8LpwKCq7naCD2/nY4HPfCuud5MCzLRL5QQCF3hnz2BzrdHnzfR7gB5mB6Cw0gPDeM2qfuZFHTQPqbEFAP66F+twnzd5HLm+ioBybOmK6Ors1ABlw2qchWb4BgNqPvz2D0Bh3akyGbVp0f3+/BrF2z0+/QbJbQ7//hRLgLdMf16IuhfjSlknR8wcGoxU/pI3StAu3pFMaX9BG2dlaRTG0htv4A2/EVxJ+tIb67xsPUBl8mYnyViDG5v8ODg10evHnMd2+fcG9/g8nkJlOHz7kdf4SnW6us1a5h5DPHTLx4yPXNFTYav1it5liqXPIid0KrVWOjfM567YbZs+/8mTlm/SZPs5zht6+feZo5ZbF0xWrlCp1um5PxEAYVYI+aSH94DaqQ3TkfSv7P7r0+tkcQwg6vEt4wCLWl2K9n9YnnIokYmTMdzuK2EdWi3PO8aANq/RrC8yBcB2JqQ9N+n7uuG4o9yn3fW+TLvRBQay3Q6lcKt3/GnailFQTBnd6yR7V/dUE4bHadJK0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Momentum of S&P 500 Sectors from 2007 to 2021",
            "title": "Momentum of S&P 500 Sectors from 2007 to 2021",
            "src": "/static/cbacf49a45fd4ec387a05f7dbad35305/c1b63/sector-momentum-abs.png",
            "srcSet": ["/static/cbacf49a45fd4ec387a05f7dbad35305/5a46d/sector-momentum-abs.png 300w", "/static/cbacf49a45fd4ec387a05f7dbad35305/0a47e/sector-momentum-abs.png 600w", "/static/cbacf49a45fd4ec387a05f7dbad35305/c1b63/sector-momentum-abs.png 1200w", "/static/cbacf49a45fd4ec387a05f7dbad35305/21b4d/sector-momentum-abs.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6a1ae2cf55a93ba155a079be0d1b99b9/21b4d/sector-momentum-abs-2019.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB2ElEQVQoz3VTa4+iQBDk//+l+7TJxts9c3oKCgqrGxcIiq4vmAczVF2GXZUPd510aqZ6erqge7yiyBFFEZIkQRzHKMsSUkpYa2mthbWGpmnorG1b53D4HafSikpLCFmzFjW8LE/hT6cIwxCBHyDLMhhjwC9zCKkM2tbCWAPdKF6qC6SqKZVgYzSNbaAaRSfEy7IUs/kMYThHGIX4SDeoRAXVaDq81hcMZ1NWSlBpQSEqVtWZxrbsWSdAaw3vUl2x2sQ4HPcAQdtakC3YCl7FCVmx5ujlB8P1itKQdXWgrHLKy5anMidguguFcgoFvKLY0J8MGM3HDOZTJHHAJP7DyfQFb28LbtINQ3/I1XzAp8ETJ5NXzvwRx+Nf/D16Zuj/5CoZYzR55a7cwlsmIUggTd+QZ+9Yv4dYr5a4nK5cxDGDyZDHQ0GtJLebgNX5yN2u5O5zT6EV823JaBFg97GkdE05X8/8n63XC878Z5KWxlgaC+LfR7//oYJ36yi+zGE3Ho46Hvf43Of3sOPxsH5OF+uaYoy5JbDnXZFuBh8F70ruCT3eoVIKXl3Xbu7YNA1u7vYu6Fxr3VXu827fz3Frx3Vz6F5D75PxUGfva6fSvZAbf1v3zzt0sb9cx0xL661XowAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Momentum of S&P 500 Sectors in 2021 (Detail)",
            "title": "Momentum of S&P 500 Sectors in 2021 (Detail)",
            "src": "/static/6a1ae2cf55a93ba155a079be0d1b99b9/c1b63/sector-momentum-abs-2019.png",
            "srcSet": ["/static/6a1ae2cf55a93ba155a079be0d1b99b9/5a46d/sector-momentum-abs-2019.png 300w", "/static/6a1ae2cf55a93ba155a079be0d1b99b9/0a47e/sector-momentum-abs-2019.png 600w", "/static/6a1ae2cf55a93ba155a079be0d1b99b9/c1b63/sector-momentum-abs-2019.png 1200w", "/static/6a1ae2cf55a93ba155a079be0d1b99b9/21b4d/sector-momentum-abs-2019.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Our next chart shows the momentum for the individual industry sectors. It is a busy chart, but the picture gets much clearer once we zoom in. First, we notice that the correlation between the sectors was much looser in 2021 than it was before the pandemic in 2019. Further, we notice that the individual sectors show swift fluctuations in their momentum over the year, most notably the energy sector. It is easy to see how this setup has created opportunities and challenges at the same time.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/75c75d65249e84b50e1dd38e714e0ec9/21b4d/sector-momentum-divergence.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABjElEQVQoz4WT247bMAxE8/+/1j62QPuwbYDtJm5sx/bKFkldZgpJiZsNUFTAgARFHYukfJjnCX1/wbkb4DYjcmZKCUU5Z5YFYLe3PT6o5sYYKSI4FKesTRMnF9pJcrdotN0+7d99AJniPQ4hVAjEErymO4T/0yMQKSBtM7XcMIRQo2KJGtJe2mOZT4ASJIJU5XUE3ICcE1X1L7DA1D4CH0F7LCihG7NfCHFM6wzV1qoGjA0yLsZN4scGFkVj9u/M65XZDe1mBXzLCYk4dhtiyg0YveM8OX76NrFf2m2ZjMmENEeGjUSqgJzxMI3mLWvA5+8TnA80UxzO3YivX17w4/WKUzehP//G5dcJb8dXzO+CYQlwPqK7Ci6T4q33GBbDaRCMi9V4NwqO5xWihkOZbPkyx58M7koToddMjaAE0PlIsUxviZs0rZLqEFeJVMu3oUZqAZoKSu1+6lHGHmJEML3JEIOh5Jhq9UNosbpfrdXzpdzaw/LoYowo7bm/+mdZAceIlPI/c1LO5c/CH3TjXZvsSkbjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Divergence of Sector Momentum from 2007 to 2021",
            "title": "Divergence of Sector Momentum from 2007 to 2021",
            "src": "/static/75c75d65249e84b50e1dd38e714e0ec9/c1b63/sector-momentum-divergence.png",
            "srcSet": ["/static/75c75d65249e84b50e1dd38e714e0ec9/5a46d/sector-momentum-divergence.png 300w", "/static/75c75d65249e84b50e1dd38e714e0ec9/0a47e/sector-momentum-divergence.png 600w", "/static/75c75d65249e84b50e1dd38e714e0ec9/c1b63/sector-momentum-divergence.png 1200w", "/static/75c75d65249e84b50e1dd38e714e0ec9/21b4d/sector-momentum-divergence.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We created a simple indicator to capture the divergence between the sector's momentum. This indicator subtracts the S&P 500's momentum from the sector's momentum, squares the results, and then sums them up for the various sectors. The chart above shows this indicator over time. It is notable that historically this divergence peaks during the rebound after a recession, when some sectors recover earlier than others. The chart shows that in the first half of 2021, the divergence was as high as in 2020. However, it dropped significantly lower in the second half of the year. Especially toward the end of 2021, we seem to be nearing familiar levels again.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/882a95a49a329c3ba4997607f27832d1/21b4d/bond-momentum.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB8ElEQVQoz11T2XKbQBDk//8qlVQe8mDHtm7LoCCBJDBC4th7u1MLRnayVVOzO1fPsROVZYHFYo4sP8I5R+ccJvLe8+sJ7yC3ztI5S2M1G3Gl1AJSC0opEF2vNbLsgORPhsulRjgfzrDewDgN6wyUFRPROAXjFI3TE9bgo7VGZK0dJFKB3oPWa3Sq5aVr6byhdorWGQKeAesDjx+M2no6j6GaMaAJWRhU1QmdrGGhKZRE38tPbEx5jI+vgcurYissglwqhahXgtIpJnlGoQ2FdFS9ZFVeaB3u/eul+yezCeWcndjdms+S66rgOV1xuVoyKxoe9ynTJOFqtqBSltp47nYnXoqS52PB/BiA/BAuzwv+fnxmWbe4NpJV3SLapxvOHr5xO//OZP2L89WMeZ5wtXzm9nXOwyHm88NPvq6f+Pryg2ky43q94Ga94D6eM9m+MI1XSHYxT+cCUV23qG4d0niLt8UTTud39G2FtpWYPTziz9tmaNz+kKPtBG5lhr654L08o+8Fbk2PzXKLXRzDOfM55aLq2LSKHuQ0EOPIphnvQo1lCqHCVO+9DTbaBh9FYwwiKSWUkrg2Am0vYLRGaK5SCjqQ1hBCDDZaKwipBt3op+40yiWi8JGttfB+2hA/bcl9Y0LQ0cbD/6f7slUD/wseMU8mbikFmAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Momentum of bonds from 2007 to 2021",
            "title": "Momentum of bonds from 2007 to 2021",
            "src": "/static/882a95a49a329c3ba4997607f27832d1/c1b63/bond-momentum.png",
            "srcSet": ["/static/882a95a49a329c3ba4997607f27832d1/5a46d/bond-momentum.png 300w", "/static/882a95a49a329c3ba4997607f27832d1/0a47e/bond-momentum.png 600w", "/static/882a95a49a329c3ba4997607f27832d1/c1b63/bond-momentum.png 1200w", "/static/882a95a49a329c3ba4997607f27832d1/21b4d/bond-momentum.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/44fb0243a8336031db5f295cbc3954e8/21b4d/bond-momentum-2019.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABt0lEQVQoz11T25LbIAz1///f9q19yKZrx6xtfAVJcE4H7GQz1YxGdyHQoXGux6+PD7Rti5QSU0pFAsjwa+SyKwsBYM75Gecb13wz43EcaPw84X6/o+s6eO9rkCdh2Q3OS7HfmW+SLz9IEUGTzH4iIPeYX8mrX+ido2Zjykq7OGVjRrp0hWWFWDwnFLV6QgYQRDB5z0ME5UjXf7L9/M35iJSkP02TUJNUfYtSGlJTRIwRzfDtuOxr5UfX8nb/Sz8vzAQH13JdHP0a6Dfj/5QSOQwLVDOCZO5HQLPM3+zbG0fX0uJC1904Dj1jDBzHntGM23Dn0HdMOTGq1CuXa83zxHnsME0e7WPiuq5o1FJ9PCS9HtI4ui9OjxslZYZoXIee2zJycB1d+8l1HrhvE5epJ5kxjQ/2X3+gKmhM9bW5Ao1C+xaoopAEHNF4hPOd/bTS+w3zNGMcpppT8lUNyPlcip1bfjVjReCpasqMlq8IaOlURZTbsuKJz2d9CBHNvu8VlKqKMq2q0UwRQoBcdsFXiReISYw4gmA7hGZW/aW+bLhu+QlkXPTUz99y2qUw53Pud3+VLz3Xmn9f/Fq4vpsF6gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Momentum of bonds in 2021",
            "title": "Momentum of bonds in 2021",
            "src": "/static/44fb0243a8336031db5f295cbc3954e8/c1b63/bond-momentum-2019.png",
            "srcSet": ["/static/44fb0243a8336031db5f295cbc3954e8/5a46d/bond-momentum-2019.png 300w", "/static/44fb0243a8336031db5f295cbc3954e8/0a47e/bond-momentum-2019.png 600w", "/static/44fb0243a8336031db5f295cbc3954e8/c1b63/bond-momentum-2019.png 1200w", "/static/44fb0243a8336031db5f295cbc3954e8/21b4d/bond-momentum-2019.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`So far, we have only looked at the stock market. However, most balanced portfolios use a mix of stocks and bonds to dampen volatility. Consequently, we need to look at bond market momentum in a similar fashion. The charts above show the momentum of various bonds since 2007. We can see that 2021 was a challenging year for bonds. Their momentum sharply dropped from the peak reached during the COVID crisis to reach negative levels in September 2020. Returns from U.S. Treasuries continued to decline until they reached their trough in March 2021. This trough marked the worst momentum since December 2021. Luckily, in late June 2021, returns turned positive again and stayed that way for the remainder of the year (except for October). `}</p>
    <p>{`In summary, 2021 was characterized by high volatility, quickly changing momentum leadership and wide divergence between the sectors. Even Treasury bonds had a difficult year, with the first half of 2021 showing negative returns. Going into 2022, we expect the sectors to correlate more closely. Volatility is likely only going back to pre-pandemic levels once the pandemic and the supply-chain crisis are resolved. On the other hand, Bonds will likely remain under pressure, especially with the outlook of the FED raising rates in 2022. In this environment, we expect corporate bonds to do better than Treasuries.`}</p>
    <h2>{`Balanced Portfolios`}</h2>
    <p>{`Armed with a basic understanding of 2021's challenges, it is time to review our portfolios. Because we track so many strategies on `}<em parentName="p">{`TuringTrader.com`}</em>{`, we won't be able to look at all of them. Instead, we will focus on our `}<a parentName="p" {...{
        "href": "/articles/all-stars-portfolios/"
      }}>{`All-Stars Portfolios`}</a>{` because we are convinced that combining multiple strategies into a meta-portfolio significantly improves the portfolio's ability to cope with ever-changing market environments.`}</p>
    <p>{`Quant strategies are often accused of suffering from hindsight bias. While it is certainly not always clear how well these portfolios will work moving forward, we'd like to note that 2021, with only minor exceptions, is an out-of-sample period for our portfolios. This makes our results highly relevant.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/63c417586dc95ce25a60888ad6c7e04e/21b4d/balanced-portfolios-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB7ElEQVQoz41Ty3LbMAzU//9Xe+300EnSpKlfsS3JlkiKT5Dc7VCx0+ZWDDnADIglsVh24zjg5fkZfd+jlMJSCkrJa1xLAQHeLebES1igxTLnzLO1PFmHJz1zZwxSjOhiCIgxolkr+hdBAPqcYbPg4B221mEKwsELfzvLFC1pr9Dznk71kCTo2k13q2h4pJTIN3Pkdjqx1ycephPna0+YK6lHyjzQqjO5aFbvwUsPuIXamAZYwCJAEajlwkEdcVED3TwwW8XsLKvRazG8I0ohgiOnce2lrdum0RpdkMiz2nGYDpxsT9cKF0/awLo4IglRKutKR6vEu7/HqCtbjftGXbfMPc3Us/pIzgsZhP9j+Ev1jXtCUkK3jEfm68joHZVW9FYxqpFuHjkZQzeNDNrQKMVpUvTzyJrT+mJvFINRKDYy6QWpAQZr0O8fufn9hN33rzzvf/AyHLmctuxfH6imPbeHRxx2DxiPDzTXPTevP6l2z1ycpr6e8fbtC06bF1rv0VU0fQXkZElneJ0Vy9pDJUukEQ+XPFnzR3t68dxsn3hv+pd5RZ8uXLRB55xdRdymLaUipMwsQhFBk1SIiTEmiGSISMsh5cRBDLNkRkkw4mmDa3l0bTo3kvHO9N19aL39ik/Cvx37NJ1a1mnjD/wHVRBtp8KtAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Performance of TuringTrader's Balanced Portfolios",
            "title": "2021 Performance of TuringTrader's Balanced Portfolios",
            "src": "/static/63c417586dc95ce25a60888ad6c7e04e/c1b63/balanced-portfolios-cumulative.png",
            "srcSet": ["/static/63c417586dc95ce25a60888ad6c7e04e/5a46d/balanced-portfolios-cumulative.png 300w", "/static/63c417586dc95ce25a60888ad6c7e04e/0a47e/balanced-portfolios-cumulative.png 600w", "/static/63c417586dc95ce25a60888ad6c7e04e/c1b63/balanced-portfolios-cumulative.png 1200w", "/static/63c417586dc95ce25a60888ad6c7e04e/21b4d/balanced-portfolios-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6dc77632fa1dabf4deb1398d7687aeec/21b4d/balanced-portfolios-annual.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABiElEQVQoz61Tu0pEMRDNDwgq2Pns/AxrsbAWwU4LQcRVwQf4ASKIsLb2oujaCKLs+gEKYiGur0JQ3MZ93HeScyS5u252tdOBZIbMzMmZmUQ8vdzjoVjER6kEpRSVUqgvawMgQCvVQHJ49RHDp5s8ruRJRcZxZGOllIiiCCKuVqxhM1P5oes+eLFGb+aZYn+KW+Wcdap6nImJ4xhCKt3ISjNT/WMZ8SLNgcUHiuNFZm8mybMdepfXMJhaayRJAmE2hwVdqmxS/Absz9xT5FaZveghxwXfRqYNIDTA2JQspWxJ+k1aAYsUuRVmCwPkZCffx+YtoPGXP8t/AMz3kRMdfB+dQ6Mu3/P/BdDOT0PDYLmAzR46tqNhAIeWihQna9gtDMIp2Q7FTjlJkpYpO6ycC9KzWqTRPXsHcbjA7fMuMxS8jUw3GNJgCd/3GYYhgyCA0Z7nwfd9tJ9FYYBKLeDGwStnro5wfrsOubfM0u4+wiikybEPu52d+R2Gvsuy/mPcJqdPrdnib98X1UkkTw+giGcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Returns of TuringTrader's Balanced Portfolios",
            "title": "2021 Returns of TuringTrader's Balanced Portfolios",
            "src": "/static/6dc77632fa1dabf4deb1398d7687aeec/c1b63/balanced-portfolios-annual.png",
            "srcSet": ["/static/6dc77632fa1dabf4deb1398d7687aeec/5a46d/balanced-portfolios-annual.png 300w", "/static/6dc77632fa1dabf4deb1398d7687aeec/0a47e/balanced-portfolios-annual.png 600w", "/static/6dc77632fa1dabf4deb1398d7687aeec/c1b63/balanced-portfolios-annual.png 1200w", "/static/6dc77632fa1dabf4deb1398d7687aeec/21b4d/balanced-portfolios-annual.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We firmly believe that performance should only be reviewed in the context of risk and against a benchmark. For a balanced portfolio, the adequate benchmark is a `}<a parentName="p" {...{
        "href": "/portfolios/classic-60-40/"
      }}>{`passive 60/40`}</a>{`. We can see that all of our balanced portfolios beat their benchmark. However, there are still remarkable differences. Our `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-tr/"
      }}>{`All-Stars Total Return`}</a>{` portfolio has delivered the best results. The main contributor to this excellent result is the `}<a parentName="p" {...{
        "href": "/articles/all-stars-portfolios/"
      }}>{`improved diversification of our All-Stars portfolios`}</a>{`: These meta-portfolios diversify across asset classes, trading approaches, and time scales. It is worth pointing out that - with a few brief exceptions, the portfolio's downside is in line with that of the 60/40 benchmark.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b03ed8f78d845ef8a01b2d94603e3798/21b4d/tt-all-stars-tr-v2-components-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB70lEQVQoz3VS2W4bMQzc//++ok0QwLltx17vrm5KpGYKbeK+lQBJjTR64HCm6/WCw+GAlCKk6kj2bsiiKK0zyneadVoHS1Wa2c7bUuMaG85b5ewFIoKpiqAUQa0VazLconH0NRlv0RBKx5YNl2C4DFyBrYAjAZLgqOzW0WrFhK5cUuclGFPt7CT6TtnLQHuaNEIqmRORC1GETIk9OGDbgBjovceUa8OWO9QAmmF8QlUgCuE9EQJ7yIRfqGGmFEfxZ5o7U1pgbA5ZZohlOucwrVti3TyZMuE8GQIteUpZWWWjyMqSvxjVM6NRoCxoDL1QutLQUbuyWEVrjVP2jiWfGeuN2TaWtjJbZOmZmUalEkOI/wfuvbWGya0Lg1sG4nKdKRJoOdD8yuZv9M7RB8fqZmq4UePKIpFdG/0yM24zkruxhoBaGyYfAo4fT3x/f8LLy4Hr6x+m5cyP45WH1yO/Pk98e3zE9XzF9bJw2QLj1xsvzw/0yXOeZ/z6/YDnzyNTTpjG7lNRzLFxQeTb4nlynVshl0KiJohUnjz46TqckBenw3f/Zm4gsvUxDaacEoZZRRq6GnxSSm1Ubbsmqsrh0VQqTBu1NZTaWJtyLGFwbHBEvjUcrv/RdI+7xt9n7u5V1fvjgHf+buyfa1o39N7xF3MKVuuJr4ZoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Performance of TuringTrader's All-Stars Total Return",
            "title": "2021 Performance of TuringTrader's All-Stars Total Return",
            "src": "/static/b03ed8f78d845ef8a01b2d94603e3798/c1b63/tt-all-stars-tr-v2-components-cumulative.png",
            "srcSet": ["/static/b03ed8f78d845ef8a01b2d94603e3798/5a46d/tt-all-stars-tr-v2-components-cumulative.png 300w", "/static/b03ed8f78d845ef8a01b2d94603e3798/0a47e/tt-all-stars-tr-v2-components-cumulative.png 600w", "/static/b03ed8f78d845ef8a01b2d94603e3798/c1b63/tt-all-stars-tr-v2-components-cumulative.png 1200w", "/static/b03ed8f78d845ef8a01b2d94603e3798/21b4d/tt-all-stars-tr-v2-components-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/abc671aa38e1d30af361e4db00487c84/21b4d/tt-all-stars-tr-v2-components-annual.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABeUlEQVQoz52Tr0/DQBTHhyFBYBAEDIb/AoUmMzjA4BBDMRYSBKAJCQ4sBgUYFAkQ3AICDGNkS2gQBMFIlmy99vrj7vsl166lLah9k0vbd/c+9319dyXrvQ3LsiCEA6WUGcw+ARBgpL4bcna7jdtmnyQYhPEarTWDIICUEiXf7sPzPPi+jyg7FgYjeh/EIXyNyWqb100RTSidrqXWOuKUQqWYFWKxOIyEpzldM0A7BipNKJUHGqu/nDgz4zTZJAVObWYc8j+HYZhL+k9/gI1e9O3ev9A+Oo0Nkex2u8MBr54NMGDv4JCdueWkQgrbHgJYbbL+RtLaZW9ljN/lKkzVpmS/UDIyTUExFnXZ05yptbj6UMfr4wLCpVF+lTdSYNKU1EW2GfkN4pjtaUyst1A6r3D/ZpxcHMHn/FreoeM4lFLSdV1zMCmEgOM4KMY86aJnu9y7+GDl6RJ3jR0EJ1vsHJ9BepImJ3JYdDc4+bmSBzcm+5PTzibpycQPK5Q9CLC2lq4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Returns of TuringTrader's All-Stars Total Return",
            "title": "2021 Returns of TuringTrader's All-Stars Total Return",
            "src": "/static/abc671aa38e1d30af361e4db00487c84/c1b63/tt-all-stars-tr-v2-components-annual.png",
            "srcSet": ["/static/abc671aa38e1d30af361e4db00487c84/5a46d/tt-all-stars-tr-v2-components-annual.png 300w", "/static/abc671aa38e1d30af361e4db00487c84/0a47e/tt-all-stars-tr-v2-components-annual.png 600w", "/static/abc671aa38e1d30af361e4db00487c84/c1b63/tt-all-stars-tr-v2-components-annual.png 1200w", "/static/abc671aa38e1d30af361e4db00487c84/21b4d/tt-all-stars-tr-v2-components-annual.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The charts above show the diversification in effect. Our `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin/"
      }}>{`Round Robin`}</a>{` strategy is the primary driver of returns. It participated nicely from the `}<em parentName="p">{`GameStop`}</em>{` frenzy in spring and from the energy sector's rally in fall. However, it was mostly flat in the middle of the year. Our `}<a parentName="p" {...{
        "href": "/portfolios/tt-vix-spritz/"
      }}>{`VIX Spritz`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-mean-kitty/"
      }}>{`Mean Kitty`}</a>{` portfolios nicely filled that gap, leading to an overall smooth equity curve throughout the year. These two strategies are well-suited for the task because their mean-reversion and volatility targeting approach nicely complement `}<em parentName="p">{`Round Robin`}</em>{`'s momentum methodology. The essential takeaway here is that, even though `}<em parentName="p">{`VIX Spritz`}</em>{` and `}<em parentName="p">{`Mean Kitty`}</em>{` could not rival `}<em parentName="p">{`Round Robin`}</em>{`'s performance in 2021, each strategy played its part in smoothing out the portfolio's equity curve.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0d98e1d42189e66616fb3d3d4279e2b2/21b4d/tt-all-stars-weekly-v2-components-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABu0lEQVQoz4VS2W7cMAz0//9g0T50kWxix5ZtWfc9E8jrLpL2oQQGPMQheGgQYsbtdsM4TTDGoNbawVIrGkiQxFODrXi0bNlzWtKsYQdqYCoNMUYMKUaEEE6HD9LJJRvZPNgCyAoUh+wl/LExqAPIml5b6t1hub9Q7ytyzhhyKfwjVyOsJL0W9GpjVBvttvD4mGnEwWginQpc3xbmkNhag10OZGNorMXQq5610NDCRqd2WDFRTYLmiHTS0ClDlwJdi7QI1NVRe0uP2GPQ8PDI1FpjSN6yyJFmHinfPmh2xdiTa3gQLiQUllaJ1tjQGJBP3VfUdautj8zBSsFlWahS5F4iAwtTb5j/EfzlATx3aLeZ+tzXSidnmr70o9sr7bFSW03jHI0U1HKh6ztV4qxiteyALplHDMgpYYjB4hh/06wT3u8jvZyYzEa7CyqxcJ/feXzcYMQrrFoZveI83TmOL1RS0O3v+PXjJ6ZZMniHodb27wBfpTiwhm+Ddso2vj5DdR/BHHgohcE5h1LKOf8FXrjsxBjT1zf0WK2d8+DVKBmM6HV6h/X5ofH9FtcfJ3vi07msM/mBq+t+beAT70BYWj6JoJsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Performance of TuringTrader's All-Stars Weekly",
            "title": "2021 Performance of TuringTrader's All-Stars Weekly",
            "src": "/static/0d98e1d42189e66616fb3d3d4279e2b2/c1b63/tt-all-stars-weekly-v2-components-cumulative.png",
            "srcSet": ["/static/0d98e1d42189e66616fb3d3d4279e2b2/5a46d/tt-all-stars-weekly-v2-components-cumulative.png 300w", "/static/0d98e1d42189e66616fb3d3d4279e2b2/0a47e/tt-all-stars-weekly-v2-components-cumulative.png 600w", "/static/0d98e1d42189e66616fb3d3d4279e2b2/c1b63/tt-all-stars-weekly-v2-components-cumulative.png 1200w", "/static/0d98e1d42189e66616fb3d3d4279e2b2/21b4d/tt-all-stars-weekly-v2-components-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/626a74aa73b2ab62156adf75bd08af01/21b4d/tt-all-stars-weekly-v2-components-annual.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABrklEQVQoz52Tv0scQRTHt7KLxBSiBMXgP2KRSpSYJoikSpEEBJUVRUhQAvamMqVFOlGENAFzCRZa6TUGTU78UQhZooWeu7Mzu7Pz/YaZO8/xTJM8eAz7Zt5nv/Pem+DopILjk2NEUYQ4jlEUhXXWVwAgQGdXqWbvzD5KhyR355nvzNKQKHROrXNkWYYgi68gpYTKsnoa4bn7dlQSSWbQPrHHdQssv6B810Xx8SvsUXvGAXVRkH6mU3TXrSXKsDPc5+cKyUrI6tOAZ32vHdAYUwPmeQ5PRQPqmw/sCPe4ZRVWplh9FvB8cNICYWCYKYVAa30r6W/mAx+GP/lk8wvL249phlv4u3/cAe3+5cXFvwO7Jw8YrIVc+NZBjrQy6h/D9QVFIv4T+OkNP2x0kyP3roGuhrZ8PtCvIZpjrsvK8NGUA2Jxowd8fp/RwMSdpjRU+N24/YNaLFYGD0Z/IFgN+b7URg4F+NX30k2YQV2hEIJSSqZpaueRSZJACIHmmJIpqnHKuZVTviqvofT9LfTSNM8WlyGVpM1RtsvN6uzrsPJ9lfUX4xe5Nmo3JW7s/QFGEycuSX4LuAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Returns of TuringTrader's All-Stars Weekly",
            "title": "2021 Returns of TuringTrader's All-Stars Weekly",
            "src": "/static/626a74aa73b2ab62156adf75bd08af01/c1b63/tt-all-stars-weekly-v2-components-annual.png",
            "srcSet": ["/static/626a74aa73b2ab62156adf75bd08af01/5a46d/tt-all-stars-weekly-v2-components-annual.png 300w", "/static/626a74aa73b2ab62156adf75bd08af01/0a47e/tt-all-stars-weekly-v2-components-annual.png 600w", "/static/626a74aa73b2ab62156adf75bd08af01/c1b63/tt-all-stars-weekly-v2-components-annual.png 1200w", "/static/626a74aa73b2ab62156adf75bd08af01/21b4d/tt-all-stars-weekly-v2-components-annual.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Our `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-weekly"
      }}>{`All-Stars Weekly`}</a>{` portfolio performed almost as nicely as `}<em parentName="p">{`All-Stars Total Return`}</em>{`. However, it shows slightly higher volatility and longer drawdowns, especially since November. When looking at the performance of the components, we can see why: the individual strategies of this portfolio are much more correlated than the components making up `}<em parentName="p">{`All-Stars Total Return`}</em>{`. There is a reason for this higher correlation: Ultimately, all three component strategies answer to momentum, even though `}<a parentName="p" {...{
        "href": "/portfolios/tt-mach-1/"
      }}>{`Mach-1`}</a>{`'s `}<em parentName="p">{`mean-variance optimization`}</em>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-rain-or-shine/"
      }}>{`Rain or Shine`}</a>{`'s `}<em parentName="p">{`trend filter`}</em>{` see momentum from different angles than `}<a parentName="p" {...{
        "href": "/portfolios/tt-stocks-on-the-loose/"
      }}>{`Stocks on the Loose`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/89d610426b2c1e53ab4e1310ec1fbf1b/21b4d/tt-all-stars-monthly-components-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB3klEQVQoz1VSi27cMAzL/3/gHm3Rob3nrpfEdvySZZFDkmu3CaBpyIQtSxw+Pm54fn7GPE1IRVBbp1lHrooinbHahm5g78Yiit47a1O62DhHwWUW3n2F1IphXXIpqwguG++LYk6dK8bYEUqHSx23oBuWanR51XaCW2xkAEQEA0w5ZfAWlEkMRrLjU/s31gqBPW14CHona8MGaQwhYMjS1oqgHeufiCrbIWLeYA9G2lljpKVMxkItmaUk5BRQcqT3HsPsM8UtZExESLRcVhFjS4wSuWhm1sKomUvPzL0yauIikdmEFQ0FymINrTUMyc8s4phbYNSJUR1rj2w9sVsmUakrm/wH0YW0uu6xcZf9QjfPDO7OJpHOBdZSKNGzLzMlefp5pKbAEkfWNFLSvIFSGdy0Aj4u9IvfhxKCx+F05vl0xNPLN97Pr6x+5PH4m2+vR76dLvz56x238xXXy5XjfeIy33g4vNBFz/s44cf3J7wfjsw5YyCMixBjNEYUfoTGazC6wm36DURW8hLAkwdCJa+L8biULweoEU2NwTsMKSUUaSxVYNrhorBKo+reZNVGkbqZvmujtoZahdqUrTXuGmWtde/hauiHP7f4fHXbPxKq+nX4qVmd+I+E6z1mhj+RFFdh4DGE7wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Performance of TuringTrader's All-Stars Monthly",
            "title": "2021 Performance of TuringTrader's All-Stars Monthly",
            "src": "/static/89d610426b2c1e53ab4e1310ec1fbf1b/c1b63/tt-all-stars-monthly-components-cumulative.png",
            "srcSet": ["/static/89d610426b2c1e53ab4e1310ec1fbf1b/5a46d/tt-all-stars-monthly-components-cumulative.png 300w", "/static/89d610426b2c1e53ab4e1310ec1fbf1b/0a47e/tt-all-stars-monthly-components-cumulative.png 600w", "/static/89d610426b2c1e53ab4e1310ec1fbf1b/c1b63/tt-all-stars-monthly-components-cumulative.png 1200w", "/static/89d610426b2c1e53ab4e1310ec1fbf1b/21b4d/tt-all-stars-monthly-components-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b2250765e1c2ccc1a683131c23b2c036/21b4d/tt-all-stars-monthly-components-annual.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABc0lEQVQoz52Su0rEQBSG8xKKFlY2voP4AL7A+gp24q2wsrEQwXbBZkWt9AlkWQURfQJT7Moi24g2QbIhM5PM/L/MJBNn10vhgeFkMud8/OcSDV/6GAwGSJIEShXQWlNr3XgABOgszUsu7vXRi1OSYFH6WMOiKCCEQKTGKaQQFlCn8Zt3VBKZMpjd6rMbZ+5Bm684YwyklIhKrckw0ylqfHOsZdJwfscCxzWwivVAYYFWaqCigYUWAue2A6A2hKV6oC25LMuJpJ9sGnjtekga35pahJ3Dv4APsaruvUemB21fIbMscyU3SX8dD1zYfebm/S1H/KBqX/JteW1qKLXCqelOTNoy3ZSl4czGkNFFC0e8AU/v+LrSanqvlEIkpbR0+r0Lv0NPaGaixOrxCEvdQ14lZ2TnBO/r+zCwO1lWCvM8p4Xai/V5nrtp/favkDkLQ4inc8i4Q2kIKQXtu1MY9t73ItgbVOuhJ3YJMDC+LfXS+7dPDBJGJTCzuWcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Returns of TuringTrader's All-Stars Monthly",
            "title": "2021 Returns of TuringTrader's All-Stars Monthly",
            "src": "/static/b2250765e1c2ccc1a683131c23b2c036/c1b63/tt-all-stars-monthly-components-annual.png",
            "srcSet": ["/static/b2250765e1c2ccc1a683131c23b2c036/5a46d/tt-all-stars-monthly-components-annual.png 300w", "/static/b2250765e1c2ccc1a683131c23b2c036/0a47e/tt-all-stars-monthly-components-annual.png 600w", "/static/b2250765e1c2ccc1a683131c23b2c036/c1b63/tt-all-stars-monthly-components-annual.png 1200w", "/static/b2250765e1c2ccc1a683131c23b2c036/21b4d/tt-all-stars-monthly-components-annual.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Our low-maintenance `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-monthly/"
      }}>{`All-Stars Monthly`}</a>{` portfolio is still leading its benchmark, but only by a thin margin. In comparison to the other balanced portfolios, it was notably trailing them over the summer of 2021. This period of low returns coincides with `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin"
      }}>{`Round Robin`}</a>{`'s sideways performance in the same period. Similar to `}<em parentName="p">{`All-Stars Weekly`}</em>{`, the monthly portfolio does not reach the same level of diversification as `}<em parentName="p">{`All-Stars Total Return`}</em>{`. `}<a parentName="p" {...{
        "href": "/portfolios/tt-straight-four/"
      }}>{`Straight Four`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-bonds-not/"
      }}>{`Bonds-NOT`}</a>{` both rely on `}<em parentName="p">{`mean-variance optimization`}</em>{`, which is conceptually related to `}<em parentName="p">{`Round Robin`}</em>{`'s momentum approach. However, investors should note that `}<em parentName="p">{`All-Stars Monthly`}</em>{`'s diversification still improved the portfolio's characteristics. Throughout the year, the portfolio's downside, measured through the `}<em parentName="p">{`Ulcer Index`}</em>{`, was significantly lower than that of its components.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e732f5d4d77036d9a6e122fde7732cac/21b4d/aggressive-portfolios-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB3ElEQVQoz41S247bQAj1//9bVVVV+9Bu1TirTZw49o5nzNwYzqkcZy+PRULAIM4Ah26ab+j7HsMwQFWhrVFbA8xAA/hJmilFE6ImwpRjXHkKgqdw40kccs7oaq3YpLW2Fb8BwNXEaxJeouCSBM/R4xRXDBI5xIheAqUUWi04v86c3Ixayh3wvYMpZg6y4kUCj+vKMSe6XDmKcPSBEoVWhJoD/epIFbIlsDiwJfoQ0DVVSDGcQkS/Lli0MOZEaiQt73Yr1HWPYWQrpAYC2EbCQ7ksCzoXI/vg6bOntQeQrrS667YrwGjvlXeQXTd/F5o1bOvrnMxEmfffiyNb5v8IPvi6ewY+AP2Fa5zoo+MSPWsNLGXiIlfGkpmyY6sLY5koeWJKjq3pvcOYFkpy2FaC4lE2Ury84jz+4fF2xnH4ysN84NN84XE8sB9/8exH/hyf8f38G0/XC1+WK1+mA2d34Zo9T37EcfiCaekZJKKzZrRmqM1Ym7B3wlchqxmjBq5NcYmJxyUyVmDb5Zod/44/aI+Zv00H/HQ3hiWgE5H9mGuFakMplU0rVfdYSmLKCSkXqG45Ra6Vt+Kpqiy14Bo959Vjw+geB/3G1sbjZ/buuQ3kzf/0/EH1ZsxgZvgHEhNXZHIVU6oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Performance of TuringTrader's Aggressive Portfolios",
            "title": "2021 Performance of TuringTrader's Aggressive Portfolios",
            "src": "/static/e732f5d4d77036d9a6e122fde7732cac/c1b63/aggressive-portfolios-cumulative.png",
            "srcSet": ["/static/e732f5d4d77036d9a6e122fde7732cac/5a46d/aggressive-portfolios-cumulative.png 300w", "/static/e732f5d4d77036d9a6e122fde7732cac/0a47e/aggressive-portfolios-cumulative.png 600w", "/static/e732f5d4d77036d9a6e122fde7732cac/c1b63/aggressive-portfolios-cumulative.png 1200w", "/static/e732f5d4d77036d9a6e122fde7732cac/21b4d/aggressive-portfolios-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b39671ec14683af4720f573b790f1333/21b4d/aggressive-portfolios-annual.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABhElEQVQoz62TO0vEQBSFxzeCYi/6D7QXa7ES8QfYWspa2dlp5aO0UcRX4YIodhYWwrqIYOMDrMSwgrLNqpBkksnkHLnJ7posWOnAcIebud+c+4h6eXuB4zioVquw1spm1gKgLLGhNcnx+OOSnTczvP58JGPC2IjGGPi+DxWZQIIQx7FEptGpbZ7rVISxxdGV4WzpjOp2kmVdSb7HRHIpDEMoE0XkT2QandrcluWGEfvmHKqVffbcT7H0uEG+XsASFEFBEECJ1IaKRn4NQPahBGgiDhcqbF87YNfDNEvbbWRxBJZMgFprqKiusBXyG3BwvkK1esBuAe4NkKfjAkxu1Gq1PwJ3+8iTsRQI0HXdfwP+1DADBDPFbPWJEeBQocKOtUP0Pszgar+ZcgIMw7QpuQ5nVGUeYEMh+uccqOUdqrsJXm4psDiaB3qeR601ZSjFSh08z0OrL9A+vlyPS8V3Fs7LWHjexFN5kfZ2Hb4OKDFJyq3q5O+oD3nOh3yRm4MPEtkGfANHFiT+FGTiqwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Returns of TuringTrader's Aggressive Portfolios",
            "title": "2021 Returns of TuringTrader's Aggressive Portfolios",
            "src": "/static/b39671ec14683af4720f573b790f1333/c1b63/aggressive-portfolios-annual.png",
            "srcSet": ["/static/b39671ec14683af4720f573b790f1333/5a46d/aggressive-portfolios-annual.png 300w", "/static/b39671ec14683af4720f573b790f1333/0a47e/aggressive-portfolios-annual.png 600w", "/static/b39671ec14683af4720f573b790f1333/c1b63/aggressive-portfolios-annual.png 1200w", "/static/b39671ec14683af4720f573b790f1333/21b4d/aggressive-portfolios-annual.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Aggressive Portfolios`}</h2>
    <p>{`Our aggressive portfolios aim to match or beat the `}<em parentName="p">{`S&P 500 index`}</em>{`, preferably at lower volatility. The chart above compares our aggressive portfolios throughout 2021 and shows how both beat their benchmark. However, in 2021, the portfolios showed slightly more downside than the index. This is somewhat expected because they invest in subsets of the stock market to beat the `}<em parentName="p">{`S&P 500`}</em>{`. Doing so will inevitably increase the short-term volatility. For aggressive portfolios, the effect of lowering volatility only applies to longer periods, when the portfolios get a chance to avoid pronounced downturns.`}</p>
    <p>{`It is worth noting that in 2021, `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-stocks/"
      }}>{`All-Stars Stocks`}</a>{` performed almost as good as `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-lev/"
      }}>{`All-Stars Leveraged`}</a>{`. This result is mainly owed to `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin/"
      }}>{`Round Robin`}</a>{` having a great year, while leveraged ETFs have suffered from the heightened volatility. Moving forward, we expect  this gap between the two portfolios to widen once volatility recedes to pre-pandemic levels.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c33f34d36a01ba2d3a6a3681822119c7/21b4d/aggressive-components-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB7ElEQVQoz41T2W7cMAz0//9fDzTZZLvOHvHKsnUflGYKO9mgfSshgBwJpDRDcXi/XXE4HGCdQ2sNvTXK7jsI/mMAmLIwFQHR6ZJQh4rTnHjRASklDNF7GGPgnYf0BkHbc0uvzL0i9AxbE9YkmGPDkgVr7JxCpYqea7I4RkdVA2opGETk43aCizhqcbgkzxfj+LIaXp3nW/A8mZl3r+jNRK1vHPXC7ixhVkArdG9prcUA6Zi7h+oOQSq0r4whos2KTc/sy0rOilgmwhmyZCJ7crkTJRK9AFahB8d1NRiWYDnNE61aqX6fWdVEmpWIjqxpT+pSdv2wM/nQEuif8bbIBqBslPU8c7ncKMGzpMT/sUfRv+Cu2a7hshim6NhCZnYrs3WsJjBoy2YdrVvoUmB2ic0X1pDJEPca0Rs6a5FLZAibLxisjxjHE8+3M56OJ96nieuq+XQb+WM8cry88NfrTxxPI96WiedV83x9p7mdaPWdi7rj+9M3HNQbU4wY2DtVydCSGCo5d88FgZlCg8qWK6RW3pulbg6VwmtyfH498cFajXf41dEagyGEgCZCqRVN6vZstrphQRfB9q02sbfzLo1NBLUIJysUqay1oqbC7P0H5W06PqdgN3518YFBEfkEX/uP1vCR03pH7x1/AEKbVW9NjTEaAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Performance of TuringTrader's Aggressive Strategies",
            "title": "2021 Performance of TuringTrader's Aggressive Strategies",
            "src": "/static/c33f34d36a01ba2d3a6a3681822119c7/c1b63/aggressive-components-cumulative.png",
            "srcSet": ["/static/c33f34d36a01ba2d3a6a3681822119c7/5a46d/aggressive-components-cumulative.png 300w", "/static/c33f34d36a01ba2d3a6a3681822119c7/0a47e/aggressive-components-cumulative.png 600w", "/static/c33f34d36a01ba2d3a6a3681822119c7/c1b63/aggressive-components-cumulative.png 1200w", "/static/c33f34d36a01ba2d3a6a3681822119c7/21b4d/aggressive-components-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b2aff5baef6d33735757d2cdd94d292b/21b4d/aggressive-components-annual.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABiklEQVQoz5WTPUvEQBCGg6UWVnJwiq21oGBvYasWgmBlYW0jiJ5iYWErVrb+A8E/ICjYKCJ+oBfBwsYmOZPsbm6TfV/ZzV3MBRsHJhOGnWe+dr03/xnvvo84TpDnuVVWLQACdBIkmrOHPsI4J2mYVc5qraGUgieDEN+djgO66ELQU/ff8+NbGkzttdnuhOWhvrFnLNTLjGFVUAjrauMSSTZ37zl9u80UwqH6RRhjkKYpPEv95aDMVkvirFBkY+eOYzcblMx75dWAWZYNBP0lA8Dde05YoGiTcUZ8mX4LDMPOP4GSbLQeOX61xPikyWhtm+YtKzo0hkKI/wLBRuuJ41eLjA6HGMyt0vi6aDnP0e12B4CoLAV1n/1aYHP/iZPXy4iORhDOr9P4WTlDB9Ral1VUlzGYoPAlEhjeesDo5QKjA4/BzArMqy6BuqvhCSGolKKU0l5MJkkCO4u6L1USUaTYOv/g3sspgotNBMdnlJ8JVKrc/NyW69XZm2+zVavsvZjqkGH+uPhWfwD39jr+YxwdpgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Returns of TuringTrader's Aggressive Strategies",
            "title": "2021 Returns of TuringTrader's Aggressive Strategies",
            "src": "/static/b2aff5baef6d33735757d2cdd94d292b/c1b63/aggressive-components-annual.png",
            "srcSet": ["/static/b2aff5baef6d33735757d2cdd94d292b/5a46d/aggressive-components-annual.png 300w", "/static/b2aff5baef6d33735757d2cdd94d292b/0a47e/aggressive-components-annual.png 600w", "/static/b2aff5baef6d33735757d2cdd94d292b/c1b63/aggressive-components-annual.png 1200w", "/static/b2aff5baef6d33735757d2cdd94d292b/21b4d/aggressive-components-annual.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Our aggressive portfolios are mainly driven by three strategies: `}<a parentName="p" {...{
        "href": "/portfolios/tt-mach-2/"
      }}>{`Mach-2`}</a>{`, `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin/"
      }}>{`Round Robin`}</a>{`, and `}<a parentName="p" {...{
        "href": "/portfolios/tt-stocks-on-the-loose/"
      }}>{`Stocks on the Loose`}</a>{`. When we look at 2021, `}<em parentName="p">{`Round Robin`}</em>{` is undoubtedly the star. The strategy achieved results almost on par with our 2x leveraged `}<em parentName="p">{`Mach-2`}</em>{` strategy while avoiding leverage and rebalancing only monthly. `}<em parentName="p">{`RoundRobin`}</em>{`'s gains mostly happened in spring and fall, when the portfolio was nicely participating in the meme stock rally and the surge in oil prices.`}</p>
    <p><em parentName="p">{`Stocks on the Loose`}</em>{` had a solid year but has taken some losses since early November. While the strategy has since regained its footing, it is still slightly trailing the S&P benchmark for the year. Because of recent volatility, `}<em parentName="p">{`Stocks on the Loose`}</em>{` has scaled back its exposure to roughly 68% in late-December. This more defensive stance might pay off, if there is more volatility ahead. Also, we consider it a good complement to `}<em parentName="p">{`Round Robin`}</em>{`'s and `}<em parentName="p">{`Mach-2`}</em>{`'s aggressive positions. In any case, we are confident that `}<em parentName="p">{`Stocks on the Loose`}</em>{` will perform nicely once the markets become slightly more directional than they have been.`}</p>
    <p><em parentName="p">{`Mach-2`}</em>{` certainly had a good year. However, the strategy is far from doubling the S&P 500's returns, the ultimate goal for a 2x leveraged strategy. Throughout 2021, leveraged ETFs have suffered from stronger than usual volatility drag, negatively impacting the strategy's performance. For 2022, we hope to see volatility decline to levels below 20 and are hopeful that `}<em parentName="p">{`Mach-2`}</em>{` will deliver closer to doulbe the S&P's returns.`}</p>
    <p>{`Investors should note that in years as bullish as 2021, many aggressive strategies fail to keep up with their benchmark. Therefore, the results of the three strategies can be considered a great success, and investors should be pleased with the profits they made. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d9225d320f957700edfbb7f0da2f59f6/21b4d/lv-portfolios-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB0klEQVQoz11Ta3PcIAz0//+H/dJp0msvlzMYbJ6S2M3gS9KbMgPiIVaLtCyrW/Hy8gLnHI6U0HvHMKOqQdQoNjhblcGYle7o2JIQw3gUoT8EqkZTQSkFi4gAGMAYIAZIYAL4bFgPw14NWza4pEhtTE+KAT7PYOAAph9rN9RaT0DqIIsMhmIsfdBnRW5GEiezPh2I7w6A83xaAOg6UMUYQsBylI5QBkoHYrEJhPMeP8fndvKfrMZ/uw/fbduw3OPK3BxF92+MOoTF+sNqYu4b93pjqnemtp7z2DyP5rm3FUMToe3M/yLaOeN1TYzlwiwbW/es3TP3lSI7zSrJwfFEWiTRNHNohmvvTD0+ihLTwVILm5ChKtOcd7J0sgspRirAvRzcc6ZPO2MNJ+hMc9VxRqkmmAVe1sPhujvewg23kBkq6Gria9z4c/O87jde/Bt+uSsu4Z0+B75HR5f/8i3+4Vv4Da13UAtTSljM7EzsgNKXVx71xi7b+VSRyCmFJvZcgFNY69GoqhQV/thW5NEYY8SSc8Y8UFE0EbReafoQ9rRdjDPZ8zlTYtN2EW6pU0Rnx/1ovPiANotiZp9RgX8MHvr62lLVr8WX3ydffK/nxxgD+ABotVrQ+L1GHAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Performance of TuringTrader's Low-Volatility Portfolios",
            "title": "2021 Performance of TuringTrader's Low-Volatility Portfolios",
            "src": "/static/d9225d320f957700edfbb7f0da2f59f6/c1b63/lv-portfolios-cumulative.png",
            "srcSet": ["/static/d9225d320f957700edfbb7f0da2f59f6/5a46d/lv-portfolios-cumulative.png 300w", "/static/d9225d320f957700edfbb7f0da2f59f6/0a47e/lv-portfolios-cumulative.png 600w", "/static/d9225d320f957700edfbb7f0da2f59f6/c1b63/lv-portfolios-cumulative.png 1200w", "/static/d9225d320f957700edfbb7f0da2f59f6/21b4d/lv-portfolios-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9aef0a793399b6fd7df4ba40cc061c45/21b4d/lv-portfolios-annual.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABeUlEQVQoz41SPUsDQRQ8a0FsbfwnVhY29iLpLATxo/FnCLaKBAKClT8hKbQRUbERvCSIJiQEJBoCXsh+zsju3V6OM4UDj7f7dnd2dudF74MPdLodjEYjJEkCKSWMMTTGuAwABOgxlZorxzF2aj2SoNL5Piql/NlIKwFrbR6eIUWesxqkARb3Y25f9P2axWyfO+sJldYsAylYDAepLZcOY1aqfT831q+VCJXCjCPHH9JAuHzUZKXa83NtNGFNTiiEQKTnKPyjOMvSgAu7r9w6HxTXELaMx2NEQgjHzsyIuaGNcUq8KesnHe5dt/k8bfL784kYvnjznLDJJPkfYQiplVd19dVgdL/BejUiL1ehnMtaQ5aeXHQX82oGNiO8RfS4iZv4lOzVYbNGCKYEZ1lomaIpeU3DG4DasMHobo31SZvhotwU92Sn0rntsiu6m4o1KQV84ypJGvJh3MLB2xlaP11nNWXa1KnCsjr3we62oDLUwjik8B2Fuccvvl9HC42367kAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "2021 Returns of TuringTrader's Low-Volatility Portfolios",
            "title": "2021 Returns of TuringTrader's Low-Volatility Portfolios",
            "src": "/static/9aef0a793399b6fd7df4ba40cc061c45/c1b63/lv-portfolios-annual.png",
            "srcSet": ["/static/9aef0a793399b6fd7df4ba40cc061c45/5a46d/lv-portfolios-annual.png 300w", "/static/9aef0a793399b6fd7df4ba40cc061c45/0a47e/lv-portfolios-annual.png 600w", "/static/9aef0a793399b6fd7df4ba40cc061c45/c1b63/lv-portfolios-annual.png 1200w", "/static/9aef0a793399b6fd7df4ba40cc061c45/21b4d/lv-portfolios-annual.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Low-Volatility Portfolios`}</h2>
    <p>{`Our low-volatility portfolios aim to outperform the aggregate bond market. Our take on these portfolios typically augments a bond-heavy allocation with some lower quality debt and equities. Our `}<a parentName="p" {...{
        "href": "/portfolios/tt-stocks-on-a-stroll/"
      }}>{`Stocks on a Stroll`}</a>{` portfolio shows nicely how this can look like. While the portfolio has higher drawdowns and more volatility than holding bonds, it achieved positive returns where bonds didn't.`}</p>
    <p>{`Unfortunately, our `}<a parentName="p" {...{
        "href": "/portfolios/tt-bonds-not/"
      }}>{`Bonds-NOT`}</a>{` portfolio did not show its full potential in 2021. Beginning in November, it incurred some losses, reflecting the bonds market's nervousness. We expect the portfolio to rotate away from U.S. Treasuries and into other asset classes in 2022. It is also worth pointing out that `}<em parentName="p">{`Bonds-NOT`}</em>{` is still a valuable portfolio component if paired with a stock-centric strategy due to its mostly negative correlation to the stock market.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`In summary, 2021 has been an excellent year for investors and a great success for `}<em parentName="p">{`TuringTrader.com`}</em>{`. Most of our portfolios have delivered on their objective and resulted in sizeable profits for investors. We are looking forward to 2022 and hope to see slightly steadier markets moving forward.`}</p>
    <p>{`Of course these are only backtested results. Some investors might ask how well these results hold up in live trading. We can confidently say that our backtests are accurate and that live trading closely tracks the hypothetical results. We recommend reading our article regarding live trading performance for a more detailed review.`}</p>
    <ButtonPrimary to="/articles/live-trading-vs-backtests/" text="Learn About Live Trading Performance" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      